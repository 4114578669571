import React from 'react';
import './VersusCard.css';

export default function VersusCard(props) {
  return (
    <div className="versus-card">
      <div className="left-side">
        <h3 className="left-text"><em>{props.white.name} ({Math.round(props.white.elo)})</em></h3>
      </div>
      <div className='middle'>
        <h3>vs.</h3>
      </div>
      <div className="right-side">
        <h3 className="right-text"><em>{props.black.name} ({Math.round(props.black.elo)})</em></h3>
      </div>
    </div>
  );
}