import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/Home';
import Rankings from './components/Rankings';
import DataMining from './components/DataMining';

export default function App() {     
    return (
      <div className="App">
          <div className='topNav'>
            <nav>
              <a href='/'><img src='/logo192.png' alt='A 2x2 chess grid serving as a logo'></img></a>
              <a href='/rankings'>Rankings</a>
            </nav>
          </div>
            <Router>
              <Routes>
                <Route exact path="/" Component={HomePage}/>
                <Route exact path="/rankings" Component={Rankings}/>
                <Route exact path="/dataminingtest" Component={DataMining}/>
              </Routes>
            </Router>
          <footer>
            
          </footer>
      </div>
    );
}