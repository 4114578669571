import './Rankings.css'
import { Component } from 'react';
import RankComponent  from './RankComponent'


async function fetchEngines() {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      return await fetch("https://us-east-1.aws.data.mongodb-api.com/app/chessthunderdome-nzruh/endpoint/engines", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result
        })
        .catch(error => console.log('error', error));
  }

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            "engines":[]
        }    
    }

    
    async componentDidMount() {  
        
        this.setState({ 
            "engines": await fetchEngines()
         })

        this.interval = setInterval(
            async () => {
                let engines = await fetchEngines();
                console.log(engines)
                this.setState({ 
                    "engines":engines
                 })
            }, 15000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <div className="ranking-content">  
                {this.state.engines.map((engine, index) => <RankComponent place={index} engine={engine}/>)}
            </div> 
        )
    }
}