import React from 'react';
import './RankComponent.css';
export default function RankComponent(props) {
    return (
        <div className='ranking-component'>
            <h1 className='placement'>#{props.place+1}</h1>
            <h3 className='rank-name'>{props.engine.name}</h3>
            <div className='rank-stats'>
                <p>ELO: {Math.round(props.engine.elo)}</p>
                <p style={{"color":"black"}}>
                <span className='wdl-generic' style={{"background-color": "green"}}>{props.engine.wins}</span>
                <span> / </span> 
                <span className='wdl-generic' style={{"background-color": "#CCAA00"}}>{props.engine.draws}</span>
                <span> / </span> 
                <span className='wdl-generic' style={{"background-color": "red"}}>{props.engine.losses}</span>
                </p>
            </div>
        </div>
    )
}