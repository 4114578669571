import { Chessboard } from "react-chessboard";
import './Home.css';
import { Component } from 'react';
import VersusCard  from './VersusCard'

async function fetchBoard() {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      return await fetch("https://us-east-1.aws.data.mongodb-api.com/app/chessthunderdome-nzruh/endpoint/currentGame", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result[0]
        })
        .catch(error => console.log('error', error));
  }

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'FEN':"rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
            'status':"Getting Game...",
            'white': {
                'name': 'None'
            },
            'black': {
                'name': 'None'
            },
            'game_id':-1
        }    
    }

    
    componentDidMount() {  
        
        this.interval = setInterval(
            async () => {
                let board = await fetchBoard();
                console.log(board)
                this.setState({ 'FEN': board['board'], 'status': board['status'], 'white': board['white_engine'][0], 'black': board['black_engine'][0], 'game_id':board['_id'] })
            }, 5000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <div className="home-content">                
                <div className='board-container'>                         
                    <VersusCard white={this.state['white']} black={this.state['black']}/>
                    <Chessboard id="BasicBoard" arePiecesDraggable={false} position={this.state['FEN']}/> 
                    <h4>GAME #{this.state['game_id']}: {this.state['status']}</h4>
                </div>                           
            </div> 
        )
    }
}